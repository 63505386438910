import React from 'react';
import Helmet from 'react-helmet';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from "gatsby-plugin-image";

import Header from '../components/Header';
import Layout from "../components/layout";

class SimplyAgree extends React.Component {
  render() {
    return(
      <Layout>
        <Helmet title={"Simplyagree Case Study | Rob Allgood Design"} />
        <svg className="top green" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1366 603">
           <path class="st0" d="M1366,183c-80,0-178-131-336-131S822,0,822,0h544V183z"/>
        </svg>
        <Header />
          <div className="container content">
            <div className="row">
              <h3>The Challenge</h3>
              <p>SimplyAgree is a leading legal tech company providing signing and closing technology for transactional attorneys. The company was looking to take the next step by modernizing an existing web application and improving user experience. Now SimplyAgree is working with some of the biggest firms in the world and was recently named to The National Law Journal's 2021 Legal Technology Trailblazers List. Here is how I helped them achieve their design goals.</p>
            </div>
          </div>
          <div className="container content">
            <h3>The Brand</h3>
            <div className="row">
              <StaticImage
                src="../images/simplyagree-logo.png"
                alt="demo image of application"
                placeholder="blurred"
                className="margin-bottom-2"
              />
            </div>
            <h5 className="margin-top-2">Color</h5>
            <div className="row margin-bottom-2">
              <div className="row">
                <div className="col colors" style={{marginLeft: -5}}>
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#5A829E"/>
                  </svg>
                  <p className="text-center">5A829E</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#8F398F"/>
                  </svg>
                  <p className="text-center">8F398F</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#43BF4D"/>
                  </svg>
                  <p className="text-center">43BF4D</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#F2B824"/>
                  </svg>
                  <p className="text-center">F2B824</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#DB3737"/>
                  </svg>
                  <p className="text-center">DB3737</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#182026"/>
                  </svg>
                  <p className="text-center">182026</p>
                </div>
              </div>
            </div>
            <h5 style={{marginTop: 80}}>Iconography</h5>
            <div className="row" className="margin-bottom-2">
              <div className="row">
                <div className="col colors flex2" style={{marginLeft: -2}}>
                  <p>Custom icons were designed for the primary actions in the SimplyAgree workflow.</p>
                </div>
                <div className="col colors">
                  <StaticImage
                    src="../images/upload-icon.png"
                    alt="demo image of application"
                    placeholder="blurred"
                  />
                </div>
                <div className="col colors">
                  <StaticImage
                    src="../images/packet-icon.png"
                    alt="demo image of application"
                    placeholder="blurred"
                  />
                </div>
                <div className="col colors">
                  <StaticImage
                    src="../images/signature-icon.png"
                    alt="demo image of application"
                    placeholder="blurred"
                  />
                </div>
                <div className="col colors">
                  <StaticImage
                    src="../images/binder-icon.png"
                    alt="demo image of application"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container content">
            <h3>UI Elements</h3>
            <div className="row">
              <div className="col">
                <StaticImage
                  src="../images/component-demo.png"
                  alt="demo image of application"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
          <div className="container content">
            <h3>The Results</h3>
            <div className="row">
              <div className="col">
                <p>The redesigned application combines intuitive restructuring and improved usability into a familiar package for transactional attorneys. Though the application is packed with information and important visual cues, by maximizing space and using subtle difference, the application achieves SimplyAgree's passion for simplicity while improving usability.</p>
              </div>
              <div className="col" style={{flex: 3}}>
                <StaticImage
                  src="../images/simplyagree-demo-2.png"
                  alt="demo image of application"
                  placeholder="blurred"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <StaticImage
                  src="../images/product-demo.png"
                  alt="demo image of application"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
      </Layout>
    )
  }
}

export default SimplyAgree
